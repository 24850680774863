/* istanbul ignore file */

const env = {};
// eslint-disable-next-line no-useless-escape
const locationRegexp = /^(?:(?:([\w\-]+)-dot-)?([\w\-]+)(?:\.front\.|\.))?([\w\-]+\.[\w\-]+)$/;
const match = window.location.hostname.match(locationRegexp) || [];
const [DOMAIN, NAME, BRANCH_NAME] = match.reverse();
const isRelease = () => BRANCH_NAME.indexOf('release') !== -1;

env.CITIBOX_API_PROTOCOL = 'https:';
if (DOMAIN) {
  if (BRANCH_NAME && !isRelease()) {
    env.CITIBOX_API_HOST = `${BRANCH_NAME}.api.${DOMAIN}`;
    env.CITIBOX_AUTH_URL = `https://${BRANCH_NAME}-dot-accounts.front.${DOMAIN}`;
  } else {
    env.CITIBOX_API_HOST = `api.${DOMAIN}`;
    env.CITIBOX_AUTH_URL = `https://accounts.${DOMAIN}`;
  }
}

env.DOMAIN = DOMAIN;
env.NAME = NAME;
env.BRANCH_NAME = BRANCH_NAME;
env.NODE_ENV = DOMAIN && DOMAIN.match(/citibox.(com|app)/) ? 'production' : 'development';

env.APP_NAME = 'accounts-webapp';
env.APP_DEFAULT_LANG = 'es';
env.APP_SUPPORTED_LANGS = 'es,fr';
env.CITIBOX_CLIENT_ID = 'v3POyEsp3bhdotZRPs5hWweXJj0tkS8hQdElCZ46';
env.CITIBOX_API_HOST = 'api.citiboxlab.com';
env.CITIBOX_CALLBACK_URL = 'https://citiboxlab.com/app';
env.SENTRY_DSN = 'https://2a9c416e10824d7eaecd0f470184dffa@o378284.ingest.sentry.io/5412067';
env.GOOGLE_ANALYTICS_PROPERTY_ID = 'UA-121440426-7';
env.APP_NAME = 'accounts';
env.APP_VERSION = '3.3.0';
window.process = { env };
export { env };